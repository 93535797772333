const integerationBaseUrl = process.env.REACT_APP_HOSPITAL_INTEGRATION_URL;
const CoustomUrls = {
  getEntitiesByCountryId: `${process.env.REACT_APP_ACCOUNT_URL}/api/Entity/GetEntitiesByCountryId`,
  getEntityStructure: `${process.env.REACT_APP_ACCOUNT_URL}/api/Entity/GetEntityStructure`,
  login: `${integerationBaseUrl}/api/login`,
  user: `${integerationBaseUrl}/api/user`,
  getEntityUsers: `${integerationBaseUrl}/api/entityusers`,
  vezeetaConfig: `${integerationBaseUrl}/api/vezeetaconfig`,
  entityConfigs: `${integerationBaseUrl}/api/config`,
  userConfigs: `${integerationBaseUrl}/api/userconfig`,
};
export default CoustomUrls;
